import { render, staticRenderFns } from "./LeftSideMenuLv3.vue?vue&type=template&id=590f8eae&scoped=true&"
import script from "./LeftSideMenuLv3.vue?vue&type=script&lang=js&"
export * from "./LeftSideMenuLv3.vue?vue&type=script&lang=js&"
import style0 from "./LeftSideMenuLv3.vue?vue&type=style&index=0&id=590f8eae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590f8eae",
  null
  
)

export default component.exports