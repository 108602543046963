import { utils } from 'glsx-common'
import Layout from '@/views/Layout'
import { constants, helper } from '@/common'
import router from '@/router'
const app = {
  namespaced: true,
  state: () => ({
    routers: null,
    menuList: [],
    currentPage: null,
    isLoading: false,
    flatten: []
  }),
  mutations: {
    SET_CURRENT_PAGE(state, page) {
      if (page.origin) page = page.origin
      state.currentPage = page
    },
    SET_MENU_LIST(state, list) {
      state.menuList = list
      // state.flatten = utils.array.flattenDeep(list)
    },
    SET_ROUTES(state, payload) {
      state.routers = payload
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    CLEAR(state) {
      state.menuList = []
      state.flatten = []
      state.currentPage = null
      state.isLoading = false
      utils.storage.removeSession(constants.SCRM_BASE_MENULIST)
    }
  },
  actions: {
    async login({ dispatch }, menus) {
      const { menuFormat, defaultPath } = await dispatch('formatSave', menus)
      await dispatch('persisted', menuFormat)
      return { defaultPath }
    },
    async logout({ dispatch, commit }) {
      commit('SET_LOADING', true)
      await dispatch('auth/logout', null, { root: true })
      setTimeout(() => {
        dispatch('init/reset', null, { root: true })
        router.replace('/login')
      }, 300)
    },
    /* eslint-disable */
    async formatSave({ dispatch }, menus) {
      const menuFormat = formatData(menus, null)
      const defaultPath = getDefaultPath(menuFormat[0])
      utils.storage.setSession(constants.SCRM_BASE_MENULIST, menuFormat)
      return { menuFormat, defaultPath }
    },
    clear({ commit }) {
      commit('CLEAR')
    },
    setCurrentPage({ commit, dispatch }, page) {
      dispatch('menu/clear', null, { root: true })
      commit('SET_CURRENT_PAGE', page)
    },
    setLoading({ commit }, isLoading) {
      commit('SET_LOADING', isLoading)
    },
    persisted({ commit }, data) {
      data.forEach(({ name, children }) => children?.length && getMenuItem(name, children))
      router.addRoute(router.ERR404)
      commit('SET_MENU_LIST', data)
    }
  }
}

/**
 * 解析后台返回数据,format 系统所需要的格式
 * @param {*} menus
 * @returns
 */
function formatData(menus, parentMenu) {
  const formatMenus = []
  for (let i = 0, j = menus.length; i < j; i++) {
    if (!menus[i]) continue
    const menu = {}
    let { menuUrl: path, id, label: title, url, children, logo: icon, name, functionUrl, entry = path } = menus[i]
    if (icon === constants.SCRM_BASE_FUNCTIONAL_FLAG) {
      const { meta = {} } = parentMenu
      meta.permissions = meta.permissions || []
      meta.permissions.push(title)
      parentMenu.meta = meta
      if (!functionUrl) continue
      path = functionUrl
      menu.hidden = true
    }
    if (!parentMenu) {
      if (!path.includes('@')) continue
      ;[entry, name] = path.split('@')
      menu.entry = entry
      menu.icon = helper.getIcon(name)
      menu.name = name
      menu.id = `${id}`
    } else {
      menu.id = `${parentMenu.id}-${id}`
      if (path == '^' && (!children || children.length == 0 || children.noHidden().length == 0)) {
        throw new Error(`“${title}”：${constants.SCRM_BASE_ERRORS.ILLEGAL_ROUTER_NO_THIRD_ROUTE.MESSAGE}`)
      }
    }
    if (title.startsWith('*')) {
      title = title.slice(1)
      menu.hidden = true
    }
    menu.title = title
    menu.path = path
    menu.url = url
    if (children) {
      menu.children = formatData(children, menu)
    }
    formatMenus.push(menu)
  }
  return formatMenus
}

// 组装路由数据
function getMenuItem(moduleName, menus) {
  const flattenMenus = utils.array.flattenDeep(menus)
  for (let i = 0, j = flattenMenus.length; i < j; i++) {
    const menu = flattenMenus[i]
    if (menu.path === '^') continue
    const route = __gen(moduleName, menu)
    router.addRoute(route)
  }
}

/**
 * 生成动态路由参数
 */
/*function __gen(moduleName, menu) {
  let { path, meta = {}, id, title, url, children, hidden = false } = menu
  let _path = ''
  let redirect = ''
  function _gen_path_(p = path) {
    return _get_path_(moduleName, p)
  }
  if (path.includes('^')) {
    // const [originPath] = path.split('^')
    _path = '^' //_gen_path_() + id
    redirect = _gen_path_(children.noHidden()[0].path)
  } else {
    _path = _gen_path_()
  }
  //路由path生成name 如 home/home/index =>HomeHomeIndex
  //排除 以:开头 比如 home/home/index/:id 参数:id 不参与生成规则
  //name 主要用于 子系统内部 params传参 如 this.$router.push({name:'HomeHomeIndex',params:{id:123}})
  const name = _path.split('/').reduce((t, n) => {
    if (!n.startsWith(':')) {
      t += n.charAt(0).toUpperCase() + n.slice(1)
    }
    return t
  }, '')
  menu.path = _path
  const route = {
    path: _path,
    component: Layout,
    redirect,
    name,
    meta: { ...meta, id, title, name, url, path: _path, hidden }
  }
  return route
}*/

function __gen(moduleName, menu) {
  let { path, meta = {}, id, title, url, hidden = false } = menu
  let _path = _get_path_(moduleName, path)
  //路由path生成name 如 home/home/index =>HomeHomeIndex
  //排除 以:开头 比如 home/home/index/:id 参数:id 不参与生成规则
  //name 主要用于 子系统内部 params传参 如 this.$router.push({name:'HomeHomeIndex',params:{id:123}})
  const name = _path.split('/').reduce((t, n) => {
    if (!n.startsWith(':')) {
      t += n.charAt(0).toUpperCase() + n.slice(1)
    }
    return t
  }, '')
  menu.path = _path
  const route = {
    path: _path,
    component: Layout,
    name,
    meta: { ...meta, id, title, name, url, path: _path, hidden }
  }
  return route
}

/**
 * 获取默认加载路由
 * @param {*} menu
 * @returns
 */
function getDefaultPath(menu) {
  const { name, children, sub = children.noHidden(), child = sub.length ? sub : children } = menu
  const path = _loop(child)
  return _get_path_(name, path)
}

/**
 * 递归获取无实际路径的菜单下的第一个子菜单的路径
 * @param {*} menus
 * @returns
 */
function _loop(menus) {
  const { path } = menus.noHidden()[0]
  if (path.includes('^')) {
    return _loop(menus[0].children)
  }
  return path
}
function _get_path_(moduleName, p) {
  return `/${moduleName}${p.startsWith('/') ? p : `/${p}`}`
}

export default app
