<template>
  <div class="com-left-side-menu-lv1">
    <GLScroll class="side-bar-menu-scroll-y">
      <div class="menu-lv1-item-wrap">
        <LeftSideMenuLv1Item v-for="item in menuList" :key="item.id" :menuData="item"></LeftSideMenuLv1Item>
      </div>
    </GLScroll>
    <el-popover v-if="showPop && menu.isCollapse" ref="pop" :reference="reference" placement="right-start" :visible-arrow="false" width="160" :open-delay="500" trigger="hover" popper-class="el-submenu-popper-base-left-side-menu">
      <LeftSideMenuLv2></LeftSideMenuLv2>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LeftSideMenuLv1',
  inject: ['bus'],
  provide() {
    return {
      isPop: true //LeftSideMenuLv2 组件判断是否POPover内
    }
  },
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    bus: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['menu'])
  },
  data() {
    return {
      reference: {},
      // 控制渲染条件
      showPop: false
    }
  },
  mounted() {
    this.bus.$on('mouseenter', this.mouseenter)
  },
  beforeDestroy() {
    this.bus.$off('mouseenter', this.mouseenter)
  },
  //https://segmentfault.com/a/1190000042463083?utm_source=sf-similar-article
  methods: {
    mouseenter(reference, onReady) {
      this.showPop = false
      this.reference = reference
      this.$nextTick(() => {
        // 等待显示的popover销毁后再 重新渲染新的popover
        this.showPop = true
        this.$nextTick(() => {
          onReady()
          // 此时才能获取refs引用
          this.$nextTick(() => {
            this.$refs.pop.doShow()
            // this.$nextTick(() => this.$refs.pop.updatePopper())
          })
        })
      })
    }
  }
}
</script>
<style lang="scss">
$width: 160px;
@mixin popup_width {
  width: $width !important;
  min-width: $width !important;
}
// .el-menu--vertical {
.el-popover.el-submenu-popper-base-left-side-menu {
  padding: 0 !important;
  margin: 0 !important;
  left: $menu_width_collapse !important;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 #7a7a7a33;
  border: none !important;
  overflow: hidden;
  // > .com-left-side-menu-lv2 {
  //   border-radius: 8px;
  //   box-shadow: 0 6px 20px 0 #7a7a7a33;
  // }
  animation: fadeInRightShort 0.05s ease-in-out forwards;
}
// }
</style>
<style lang="scss" scoped>
.com-left-side-menu-lv1 {
  position: relative;
  width: $menu_width_collapse;
  height: 100%;
  .side-bar-menu-scroll-y {
    /deep/.el-scrollbar__wrap {
      margin-bottom: 0 !important ;
    }
    height: calc(100vh - #{$logo-height + 36px});
  }
  // border-right: 1px solid #f5f7fa;
  .menu-lv1-item-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
