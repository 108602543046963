<template>
  <div class="scrm-base-home">
    <div class="login-header animate__animated animate__fadeInDown">
      <el-image :src="`${$config.cdn}/assets/${$config.customized.assets}/logo_login.png`"></el-image>
      <h2>专注汽车行业数字化+智能营销解决方案——帮您的流量变销量</h2>
    </div>
    <div class="login-wrap animate__animated" :class="{ animate__fadeInUp: isDoAnimate }" v-loading="isMutilMerchantLogin">
      <div class="merchants" v-if="isHasMutilMerchants">
        <i class="icon el-icon-arrow-left forback" @click="forback"></i>
        <div class="wrap">
          <span class="title">请选择登录商户号</span>
          <GLScroll class="login-merchants-scroll">
            <div class="item-wrap">
              <div class="merchant-item" v-for="merchant in merchants" :key="merchant.uuid || merchant.id" @click.stop="loginChoose(merchant)">
                <i class="i i-dot"></i>
                <div class="merchant-info">
                  <div class="merchant-item-name">{{ merchant.merchantName }}</div>
                  <div class="merchant-item-code">商户编号：{{ merchant.merchantId }}</div>
                </div>
                <div class="merchant-item-level">{{ merchant.accountLevel | accountLevel }}</div>
                <i class="icon el-icon-arrow-right"></i>
              </div>
            </div>
          </GLScroll>
        </div>
      </div>
      <div class="login-form" v-else>
        <div class="left">
          <!-- <el-carousel height="500px" :autoplay="true" arrow="never"> -->
          <!-- <el-carousel-item v-for="item in 3" :key="item"> -->
          <el-image :src="`${$config.cdn}/assets/images/banner1.png`"></el-image>
          <!-- </el-carousel-item> -->
          <!-- </el-carousel> -->
        </div>
        <div class="right">
          <el-alert v-show="errMsg" :title="errMsg" type="error" show-icon class="animate__animated" :class="{ animate__headShake: !!errMsg }"></el-alert>
          <span class="welcome">欢迎登录</span>
          <el-form :model="loginForm" @keyup.enter.native="submitForm" :rules="rules" ref="loginForm" class="form">
            <el-form-item prop="userName">
              <el-input clearable v-model.trim="loginForm.userName" placeholder="请输入手机号/用户名" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" clearable v-model.trim="loginForm.password" placeholder="请输入密码" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input type="code" v-model.trim="loginForm.code" maxlength="4" placeholder="请输入验证码" @keyup.enter.native="submitForm">
                <div class="code-suffix" slot="suffix" @click="getCode">
                  <el-image :src="code64"></el-image>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()" :disabled="disabled" :class="{ isLogin }">
                <span v-show="!isLogin" class="login-text">登录</span>
                <span v-show="isLogin">登录中...</span>
              </el-button>
            </el-form-item>
            <div class="privacy" v-if="$config.isStandard">
              <el-checkbox v-model="isPrivacyChecked"></el-checkbox>
              请阅读并同意
              <a :href="`${$config.cdn}/html/privacy.html`" target="_blank">
                <i class="el-icon-d-arrow-left"></i>
                <span>隐私协议</span>
                <i class="el-icon-d-arrow-right"></i>
              </a>
              及
              <a :href="`${$config.cdn}/html/agreement.html`" target="_blank">
                <i class="el-icon-d-arrow-left"></i>
                <span>用户协议</span>
                <i class="el-icon-d-arrow-right"></i>
              </a>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <LoginSMSCodeCheck :visible.sync="smsCodeDialogVisible" :checkProps="smsCheckProps" @onSuccess="afterVerifyPhone"></LoginSMSCodeCheck>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { utils } from 'glsx-common'
import api from '@/api'
import md5 from 'md5'
import { Message } from '@/plugins/element'
import { constants, helper } from '@/common'
import LoginSMSCodeCheck from './components/LoginSMSCodeCheck.vue'
const genRules = field => [{ required: true, message: `请输入${field}`, trigger: 'blur' }]
const RULES = Object.freeze({
  userName: genRules('用户名'),
  password: genRules('密码'),
  code: genRules('验证码')
})
const ERROR_TYPE = Object.freeze({
  TIP: 0,
  MESSAGE: 1
})
export default {
  name: 'Login',
  mounted() {
    this.getCode()
    this.clearAmimate()
  },
  components: {
    [LoginSMSCodeCheck.name]: LoginSMSCodeCheck
  },
  data() {
    return {
      loginForm: {
        userName: '',
        password: '',
        codeToken: '',
        code: '',
        loginToken: ''
      },
      merchants: [],
      code64: '',
      errMsg: '',
      isLogin: false,
      isDoAnimate: true,
      showTip: false,
      isHasMutilMerchants: false,
      smsCodeDialogVisible: false,
      rules: RULES,
      //手机号验证组件所需参数
      smsCheckProps: {
        mobile: '13800138000'
      },
      isPrivacyChecked: true,
      chooseMerchant: null,
      isMutilMerchantLogin: false
    }
  },
  watch: {
    smsCodeDialogVisible: function (val) {
      if (!val) {
        this.isLogin = false
        this.isMutilMerchantLogin = false
        !this.isHasMutilMerchants && this.getCode()
      }
    }
  },
  computed: {
    disabled() {
      return !this.loginForm.userName || !this.loginForm.password || !this.loginForm.codeToken || !this.loginForm.code || this.isLogin || !this.isPrivacyChecked
    },
    ...mapGetters(['app'])
  },
  /**
   * 进入页面前 如果qiankun已运行 清空session数据1
   * 并刷新本页面 qiankun重新初始化(因为qiankun暂时不支持卸载加载过的应用,无法再次初始化子应用的信息)
   */
  beforeRouteEnter(to, from, next) {
    if (window.qiankunStarted) {
      const session = utils.storage.getSession(constants.SCRM_BASE_SESSION)
      sessionStorage.clear()
      const redirect = window.history.state?.page?.split('/').slice(3).join('/')
      const path = `${session?.loginpath || '/login'}${redirect ? `?redirect=${redirect}` : ''}`
      return location.replace(path)
    }
    next()
  },
  methods: {
    /**
     * 登录
     * 1.校验输入
     * 2.MD5加密密码
     * 3.调登录接口登录
     * 4.调接口判断是否要验证短信验证码  插入流程 2022-11-07
     * 5.判断是否有多个商户(有:弹出选择商户的界面 没有:获取菜单信息)
     */
    submitForm: utils.debounce(function () {
      this.errMsg = ''
      this.$refs['loginForm'].validate(async valid => {
        if (valid) {
          this.isLogin = true
          const { response, loginSource, productCode } = this.$config
          const loginData = Object.assign({ loginSource, productCode }, this.loginForm, { password: md5(this.loginForm.password).toLowerCase() })
          let { code, data, message } = await api.app.login(loginData)
          if (code === response.code_success_value) {
            //判断是否绑定多个商户。
            this.checkMutilMerchants(data)
          } else {
            this.error(message)
          }
        }
      })
    }, 500),
    /**
     * 判断是否绑定多个商户。如果有多个，则打开选择界面
     * 如果只有一个，直接判断是否要验证短信验证码
     */
    checkMutilMerchants(data) {
      const { loginList: merchants, loginToken } = data
      if (merchants.length > 1) {
        this.loginForm.loginToken = loginToken
        this.merchants = Object.freeze(merchants)
        this.doAnimate()
        this.isHasMutilMerchants = true
      } else {
        this.verifyPhone(merchants[0])
      }
    },
    /**
     * 获取商户下的菜单信息
     */
    async _permission(merchant) {
      if (!merchant) throw new Error('商户信息不能为空')
      try {
        let { response, track } = this.$config
        const { code, message, menus, sessionData } = await this.permission(Object.assign({ pw: md5(this.loginForm.password) }, merchant))
        if (code == response.code_success_value) {
          const { defaultPath } = await this.login(menus)
          Object.assign(sessionData, { defaultPath, loginpath: location.pathname, track })
          this.setSession(sessionData)
          this.setTracker(sessionData)
          this.storeConfig()
          const path = helper.locationPath()
          const redirect = path?.get('redirect')
          const to = redirect ? `/${decodeURIComponent(redirect)}` : defaultPath
          // this.$router.push(defaultPath)
          this.$router.onReady(() => this.$router.push(to))
        } else {
          this.error(message)
        }
      } catch (error) {
        this.error(error.message, ERROR_TYPE.MESSAGE)
      } finally {
        this.isLogin = false
        this.isMutilMerchantLogin = false
      }
    },
    /**
     * 账户下有多个商户 弹出选择商户
     * 1.获取选择的商户信息
     * 2.获取菜单信息并登录
     */
    loginChoose(merchant) {
      if (this.isMutilMerchantLogin) return
      this.isMutilMerchantLogin = true
      this.verifyPhone(merchant)
    },
    /**
     * 登录成功，验证本账号是否需要短信验证登录
     * 如果不需要验证，直接调取是否选择门店方法
     * 如果需要验证，弹出验证窗口
     */
    async verifyPhone(merchant) {
      try {
        let { response } = this.$config
        const loginAccount = this.loginForm.userName
        const { merchantId, userType } = merchant
        const { code, message, data: mobile } = await api.app.verifyPhone({ loginAccount, merchantId, userType })
        if (code == response.code_success_value) {
          this.chooseMerchant = Object.freeze(merchant)
          if (mobile) {
            //如果返回手机号,则弹出验证窗口
            this.smsCodeDialogVisible = true
            Object.assign(this.smsCheckProps, { mobile })
          } else {
            //如果没有手机号,跳过手机号验证
            this.afterVerifyPhone()
          }
        } else {
          this.error(message)
        }
      } catch (error) {
        this.error(error.message, ERROR_TYPE.MESSAGE)
      }
    },

    /**
     * 验证短信验证码后,判断是否多商户
     * 1.如果是多商户,需要重新登录 并获取菜单信息
     * 2.如果不是多商户,直接获取菜单信息
     */
    afterVerifyPhone() {
      let merchant = this.chooseMerchant
      this.$nextTick(async () => {
        //如果是多商户,需要重新登录 并获取菜单信息
        if (this.isHasMutilMerchants) {
          this.isMutilMerchantLogin = true
          merchant = await this.reLoginAfterChooseMerchantSuccess(merchant.merchantId)
        }
        this._permission(merchant)
      })
    },
    /**
     * 选择商户成功后重新登录
     */
    async reLoginAfterChooseMerchantSuccess(merchantId) {
      const { response, loginSource } = this.$config
      const { userName: loginAccount, loginToken } = this.loginForm
      const { code, message, data: merchant } = await api.app.choose({ merchantId, loginAccount, loginToken, loginSource })
      if (code === response.code_success_value) {
        return merchant
      }
      this.error(message)
      return null
    },
    storeConfig() {
      utils.storage.setSession(constants.SCRM_BASE_CONFIG, this.$config)
    },
    async getCode() {
      this.loginForm.code = ''
      sessionStorage.clear()
      const { data, token } = await api.app.code({})
      this.code64 = data
      this.loginForm.codeToken = token
    },
    /**
     * 根据传入的错误信息和类型，处理错误并重新获取验证码
     * @param {string} message - 错误信息
     * @param {boolean} isHasMutilMerchants - 是否存在多个商家
     */
    error(message, type = this.isHasMutilMerchants) {
      this.isLogin = false
      this.isMutilMerchantLogin = false
      ~[() => (this.errMsg = message), () => Message.error(message)][+type].call()
      this.getCode()
    },
    doAnimate() {
      this.isDoAnimate = true
      this.clearAmimate()
    },
    clearAmimate() {
      setTimeout(() => (this.isDoAnimate = false), 500)
    },
    /**
     * 选择商户界面返回
     */
    forback() {
      this.isLogin = false
      this.doAnimate()
      this.getCode()
      this.isHasMutilMerchants = false
      this.chooseMerchant = null
      this.isMutilMerchantLogin = false
    },
    ...mapActions('app', ['login']),
    ...mapActions('auth', ['permission', 'setSession']),
    ...mapActions('track', ['setTracker'])
  }
}
</script>
<style lang="scss" scoped>
$border-radius: 20px;
.scrm-base-home {
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: $main-color;
  background: url('https://img02.glsx.com.cn/weapp/resource/saas/scrm/assets/images/bg.png') no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  .login-header {
    text-align: center;
    margin: 87px 0 44px 0;
    h2 {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .login-wrap {
    width: 984px;
    height: 500px;
    position: relative;
    box-shadow: 15px 18px 64px 0px rgba(210, 210, 210, 0.13);
    border-radius: $border-radius;
    overflow: hidden;
    .forback {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 16px;
      z-index: 1234;
      padding: 10px;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
        border-radius: 50%;
      }
    }
    .merchants {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.el-scrollbar__wrap {
        overflow-x: hidden;
        margin-bottom: 0 !important ;
      }
      .wrap {
        width: 556px;
        height: 352px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 24px;
          color: #3a3a3a;
          letter-spacing: 1.2px;
          font-weight: 700;
          margin-bottom: 40px;
        }
        .item-wrap {
          height: 288px;
          box-sizing: border-box;
        }
        .merchant-item {
          width: 556px;
          height: 73px;
          min-height: 73px;
          background: #fafafa;
          border-radius: 4px;
          position: relative;
          display: flex;
          box-sizing: border-box;
          padding-left: 18px;
          cursor: pointer;
          .merchant-info {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          & + .merchant-item {
            margin-top: 30px;
          }
          i.i {
            transform: scale(0.5);
            margin: 20px 6px 0 0;
          }
          &-name {
            font-size: 16px;
            color: #8d8d8d;
            letter-spacing: 0;
            font-weight: 500;
            max-width: 392px;
            word-wrap: break-word;
            word-break: break-all;
          }
          &-code {
            font-size: 12px;
            color: #adadad;
            letter-spacing: 0;
            font-weight: 500;
            margin-top: 6px;
          }
          &-level {
            border: 1px solid #5850ff;
            border-radius: 4px;
            font-size: 14px;
            color: $main-color;
            font-weight: 400;
            padding: 1px 24px;
            width: 28px;
            height: 22px;
            line-height: 22px;
            margin: 14px 0 0 10px;
          }
          i.icon {
            position: absolute;
            top: 27px;
            right: 20px;
          }
          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
      .login-merchants-scroll {
        height: 290px;
      }
    }
    .login-form {
      display: flex;
      height: 100%;
      .left {
        flex: 0 0 492px;
        border-radius: $border-radius 0 0 $border-radius;
        .el-image {
          height: 100%;
        }
        /deep/.el-carousel {
          .el-carousel__button {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background-color: #fff;
            opacity: 0.8;
          }
          .el-carousel__indicator {
            &.is-active {
              .el-carousel__button {
                background-color: #fe911a;
                opacity: 1;
              }
            }
            padding: 17px 4px;
          }
        }
      }
      .right {
        flex: 0 0 492px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        border-radius: 0 $border-radius $border-radius 0;
        .el-alert {
          position: absolute;
          top: 12px;
          width: 345px;
          background: #fff1f0;
          border-radius: 4px;
          border: 1px solid #eb4e4e;
          padding: 2px 16px;
          min-height: 35px;
        }
        .error-tip {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 341px;
          min-height: 32px;
          background: #fff1f0;
          border-radius: 4px;
          border: 1px solid #eb4e4e;
          font-size: 13px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          padding: 6px 6px 6px 8px;
          top: 20px;
          position: absolute;
          box-sizing: border-box;
          i.el-icon-error {
            color: #eb4e4e;
            margin-right: 4px;
          }
          i.el-icon-close {
            color: #000;
            cursor: pointer;
          }
        }
        /deep/.el-form {
          width: 342px;
          .el-form-item__content {
            margin-top: 8px;
            .el-input__inner {
              font-family: $font-family;
              font-weight: 200;
              letter-spacing: 1px;
              &:focus {
                border-color: $main-color;
              }
            }
            .el-input__suffix-inner {
              .code-suffix {
                .el-image {
                  cursor: pointer;
                  line-height: 28px;
                  vertical-align: middle;
                }
              }
            }
            .el-button--primary {
              width: 341px;
              height: 42px;
              background: $main-color;
              border-radius: 4px;
              font-size: 18px;
              text-indent: 1em;
              border: 2px solid rgba(254, 145, 26, 0.1);
              &.is-disabled,
              &.is-disabled:hover {
                background: #e9e9e9;
                border-color: #e9e9e9;
                color: #999;
              }
              &.is-disabled.isLogin {
                background: $main-color;
                border: 2px solid rgba(254, 145, 26, 0.1);
                color: rgba($color: #fff, $alpha: 0.6);
              }
              &:hover,
              &:focus {
                border-color: #7777f3;
                background-color: #7777f3;
              }
              span.login-text {
                position: relative;
                letter-spacing: 1em;
                top: -1px;
              }
            }
          }
        }
        .welcome {
          // width: 101px;
          height: 33px;
          font-size: 24px;
          font-weight: 500;
          color: #333;
          line-height: 33px;
          letter-spacing: 1px;
          margin-bottom: 30px;
        }
      }
      .privacy {
        // margin-top: -8px;
        font-size: 12px;
        font-weight: 500;
        color: #999;
        line-height: 20px;
        display: flex;
        align-items: center;
        /deep/.el-checkbox {
          margin-right: 6px !important;
          &__inner {
            border-color: #c0c4cc;
            &:hover {
              border-color: $main-color;
            }
          }
          &__input.is-checked {
            .el-checkbox__inner {
              border-color: $main-color;
              background-color: $main-color;
            }
          }
        }
        a {
          cursor: pointer;
          color: $main-color;
          font-weight: 600;
        }
      }
    }
  }
  /deep/.el-dialog {
    &__header,
    &__body,
    &__footer {
      padding: 0;
    }
  }
}
</style>
