<!--
 * @Author: lizhijie429
 * @Date: 2021-08-19 11:16:08
 * @LastEditors: limin
 * @LastEditTime: 2023-11-17 17:41:53
 * @Description: 
-->
<template>
  <div class="logo" :class="{ collapse: menu.isCollapse }">
    <!-- 防止切换图片闪烁，折叠和展开分别用标签展示 -->
    <el-image :src="src_logo_menu" style="width: 176px"></el-image>
    <el-image :src="src_logo_collapse" style="width: 48px"></el-image>
    <div class="folder" @click="toggleFolder()">
      <i class="i i-left"></i>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Logo',
  computed: {
    ...mapGetters(['menu'])
  },
  watch: {
    $config: {
      handler(n) {
        const { cdn, customized, assets = customized.assets } = n
        const src = `${cdn}/assets/${assets}`
        this.src_logo_menu = `${src}/logo_menu_new_common.png`
        this.src_logo_collapse = `${src}/logo_collapse_new_common.png`
      },
      immediate: true
    }
  },
  data() {
    return {
      src_logo_menu: '',
      src_logo_collapse: ''
    }
  },
  methods: {
    ...mapActions('menu', ['toggleFolder'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.logo {
  height: 100%;
  background: #fff;
  position: relative;
  .folder {
    position: absolute;
    right: 0;
    bottom: -24px;
    width: 30px;
    height: 24px;
    background-color: #f1f1f5;
    border-radius: 12px 0 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 12px;
      color: #a4a3a3;
      transition: 0.2s;
    }
  }
  .el-image {
    position: absolute;
    top: 50%;
    left: 50%;
    &:nth-child(1) {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    &:nth-child(2) {
      opacity: 0;
      transform: translate(112px, -50%);
    }
    transition: transform 0.18s ease, opacity 0.1s ease;
  }
  &.collapse {
    i {
      transform: rotate(180deg);
    }
    .el-image {
      &:nth-child(1) {
        opacity: 0;
        transform: translate(-208px, -50%);
      }
      &:nth-child(2) {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
  }
  &::after {
    position: absolute;
    content: '';
    width: 160px;
    border-bottom: 1px solid #f5f7fa;
    height: 0;
    top: 70px;
    right: 0;
    z-index: 2;
  }
}
</style>
